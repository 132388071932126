import React, { Component } from 'react';
import dynamic from 'next/dynamic';
const NotLoggedInNav = dynamic(() => import('../nav/NotLoggedInNav'), {
    ssr: false,
  });
const NotLoggedInFooter = dynamic(() => import('../nav/NotLoggedInFooter'), {
ssr: false,
});

class NotLoggedInLayout extends Component<any, {}> {
    render() {
        
        const { children } = this.props;
        return (
                <div className="primary-layout">
                <NotLoggedInNav />
                {children}
                <NotLoggedInFooter />
                </div>
        )
    }
}

export default NotLoggedInLayout;
