import React from 'react';
import dynamic from 'next/dynamic';
import Utils from '@ts-tools/utils';
import {
  TypographyScale,
  Button,
  FlashCard,
  Link,
  MasonryCard,
} from '@mdb/flora';
import Router from 'next/router';
import Head from 'next/head';
import { Body } from '@leafygreen-ui/typography';
import { SUPPORT_POLICY_URL } from '../constants/urls';
import '../css/welcome.scss';

const Page = dynamic(() => import('../components/Page'), {
  ssr: false,
});
import NotLoggedInLayout from '../components/layouts/NotLoggedInLayout';

export async function getServerSideProps(ctx: any) {
  let session = Utils.Cookies.Read.MAIN(ctx);
  if (session != null) {
    return {
      redirect: {
        destination: '/home',
        permanent: false,
      },
    };
  } else {
    // // ADD SPLUNK OUTPUT

    // NEED TO CONFIRM IF VERBOSE ISN'T AVAILABLE IN LOGGER ANYMORE

    Utils.NextHandlers.Logger(ctx.req, session, {
      isSecure: false,
      recorder: 'index',
    });
    return {
      props: {},
    };
  }
  // } else if (session != null) {
  //     return {
  //         props: { session: Utils.Session.ClientSanitize(session) }, // Will be passed to the page component as props
  //     }
  // }
}

const Index = () => {
  return (
    <Page pageProps={{}} className="no-page-margin">
      <Head>
        <title>MongoDB Support</title>
      </Head>{' '}
      <div className="support-portal-welcome-hero-wrap">
        <header className="support-portal-welcome-hero">
          <div className="support-portal-welcome-hero-text">
            <TypographyScale variant="heading2" inverse={true} color="selected">
              MongoDB
            </TypographyScale>
            <TypographyScale variant="heading2" inverse={true}>
              Support Portal
            </TypographyScale>
            <div className="support-portal-welcome-hero-text-sub">
              <TypographyScale
                className="support-portal-welcome-hero-text-sub-type"
                variant="body1"
                inverse={true}>
                Commercial Support subscribers can sign in to read our extensive
                Knowledge Base, escalate an issue, or create and manage cases.
              </TypographyScale>
              <Button
                size="large"
                className="support-portal-welcome-hero-button"
                onClick={() => {
                  Router.push('/api/auth/login');
                }}>
                Sign In
              </Button>
            </div>
          </div>
          <div className="ImageHeader__Image-nl7yxk-5 support-portal-welcome-hero-image">
            <img
              className="support-portal-welcome-hero-image-svg"
              src="/images/Support_Illo.svg"
              alt=""
            />
          </div>
        </header>

        <section className="support-portal-welcome-hero-sub">
          <TypographyScale
            variant="heading3"
            inverse={true}
            className="support-portal-welcome-hero-sub-type">
            MongoDB offers help with training, upgrading, and more.
          </TypographyScale>
        </section>
        <section className="support-portal-welcome-hero-links">
          <a
            href="//www.mongodb.com/products/enterprise-grade-support"
            target=""
            style={{ border: '1px solid rgba(184,196,194,0.25)' }}
            className="support-portal-custom-card clickable hover-lift">
            <div className="flex-row flex-1">
              <div className="flex-column flex-center">
                <div className="flex-center">
                  <div
                    className="absolute"
                    style={{
                      width: '1px',
                      height: '1px',
                      top: '-200px',
                      background: 'transparent',
                    }}></div>
                  <div
                    className="absolute"
                    style={{
                      width: '1px',
                      height: '1px',
                      top: '-200px',
                      background: 'transparent',
                    }}></div>
                  <div style={{ width: '48px' }}>
                    <img
                      src="/images/icons/General_FEATURES_Support3x.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="support-portal-custom-card-text">
                <Body className="support-portal-custom-card-text-header">
                  Commercial Support
                </Body>
                <div className="text-left">
                  <Link linkIcon="chevron">Learn More</Link>
                </div>
              </div>
            </div>
          </a>
          <a
            href="//www.mongodb.com/products/consulting"
            target=""
            style={{ border: '1px solid rgba(184,196,194,0.25)' }}
            className="support-portal-custom-card clickable hover-lift">
            <div className="flex-row flex-1">
              <div className="flex-column flex-center">
                <div className="flex-center">
                  <div
                    className="absolute"
                    style={{
                      width: '1px',
                      height: '1px',
                      top: '-200px',
                      background: 'transparent',
                    }}></div>
                  <div
                    className="absolute"
                    style={{
                      width: '1px',
                      height: '1px',
                      top: '-200px',
                      background: 'transparent',
                    }}></div>
                  <div style={{ width: '48px' }}>
                    <img
                      src="/images/icons/General_EVENTS_Breakout3x.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="support-portal-custom-card-text">
                <Body className="support-portal-custom-card-text-header">
                  Training &amp; Consulting
                </Body>
                <div className="text-left">
                  <Link linkIcon="chevron">Learn More</Link>
                </div>
              </div>
            </div>
          </a>
          <a
            href={SUPPORT_POLICY_URL}
            target=""
            style={{ border: '1px solid rgba(184,196,194,0.25)' }}
            className="support-portal-custom-card clickable hover-lift">
            <div className="flex-row flex-1">
              <div className="flex-column flex-center">
                <div className="flex-center">
                  <div
                    className="absolute"
                    style={{
                      width: '1px',
                      height: '1px',
                      top: '-200px',
                      background: 'transparent',
                    }}></div>
                  <div
                    className="absolute"
                    style={{
                      width: '1px',
                      height: '1px',
                      top: '-200px',
                      background: 'transparent',
                    }}></div>
                  <div style={{ width: '48px' }}>
                    <img
                      src="/images/icons/General_ACTION_Audit3x.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="support-portal-custom-card-text">
                <Body className="support-portal-custom-card-text-header">
                  Support Policy
                </Body>
                <div className="text-left">
                  <Link linkIcon="chevron">Learn More</Link>
                </div>
              </div>
            </div>
          </a>
        </section>
      </div>
      <section className="support-portal-welcome-subsection">
        <TypographyScale
          variant="heading3"
          className="support-portal-welcome-subsection-header">
          Take advantage of our free resources, online courses, and community
          forums.
        </TypographyScale>
      </section>
      <section className="support-portal-welcome-card-section">
        <div className="support-portal-welcome-card-container">
          <FlashCard
            className="clickable hover-lift support-portal-welcome-card"
            imageryType="icon"
            iconConfig={{
              name: 'general_content_tutorial',
              size: 'medium',
            }}
            title="MongoDB Manual"
            text="Learn key concepts in MongoDB, the query language, and
                operational and administrative considerations as well as
                comprehensive references."
            cta={{
              type: 'link-chevron',
              text: 'Learn More',
              config: {
                href: '//docs.mongodb.com/manual/',
              },
            }}
          />
          <FlashCard
            className="clickable hover-lift support-portal-welcome-card"
            imageryType="icon"
            iconConfig={{
              name: 'cloud_multicloud',
              size: 'medium',
            }}
            title="Cloud Product"
            text="Familiarize yourself with MongoDB’s Cloud Service Products
                and discover the perfect framework for your data
                infrastructure."
            cta={{
              type: 'link-chevron',
              text: 'Learn More',
              config: {
                href: '//docs.mongodb.com/cloud/',
              },
            }}
          />
          <FlashCard
            className="clickable hover-lift support-portal-welcome-card"
            imageryType="icon"
            iconConfig={{
              name: 'general_content_ebook',
              size: 'medium',
            }}
            title="MongoDB Developer Center"
            text="The latest MongoDB tutorials, videos and code examples with your languages and tools. 
            A global community of more than 7 million developers. 
            Build something {big} with MongoDB."
            cta={{
              type: 'link-chevron',
              text: 'Learn More',
              config: {
                href: '//mongodb.com/developer/',
              },
            }}
          />
          <FlashCard
            className="clickable hover-lift support-portal-welcome-card"
            imageryType="icon"
            iconConfig={{
              name: 'general_content_community',
              size: 'medium',
            }}
            title="Discussion Forum"
            text="A community forum best suited for open-ended,
                opinion-based, or general questions about anything MongoDB
                related."
            cta={{
              type: 'link-chevron',
              text: 'Learn More',
              config: {
                href: '//community.mongodb.com/',
              },
            }}
          />
          <FlashCard
            className="clickable hover-lift support-portal-welcome-card"
            imageryType="icon"
            iconConfig={{
              name: 'general_content_learn',
              size: 'medium',
            }}
            title="MongoDB University"
            text="MongoDB University offers simple, free online courses to
                teach you how to easily start building and deploying apps
                on MongoDB."
            cta={{
              type: 'link-chevron',
              text: 'Learn More',
              config: {
                href: '//learn.mongodb.com/',
              },
            }}
          />
          <FlashCard
            className="clickable hover-lift support-portal-welcome-card"
            imageryType="icon"
            iconConfig={{
              name: 'general_features_automation',
              size: 'medium',
            }}
            title="MongoDB Certification"
            text="Certifications recognize developers and DBAs with the
                Knowledge needed to build and maintain MongoDB
                applications."
            cta={{
              type: 'link-chevron',
              text: 'Learn More',
              config: {
                href: '//learn.mongodb.com/pages/certification-program/',
              },
            }}
          />
        </div>
      </section>
    </Page>
  );
};

(Index as any).Layout = NotLoggedInLayout;

export default Index;
